import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}
let nClass = 'hidden'
let creditsClass = 'mt-6 text-sm text-gray-500 dark:text-gray-300'
const searchParams = new URLSearchParams(document.location.search)
if (searchParams.get('app') === 'true') {
  nClass = 'n'
  creditsClass = 'hidden'
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Hvordan spille Nordle"
      hideTitleOnApp={true}
      centered={true}
      isOpen={isOpen}
      closeButtonTitle="Start spillet"
      handleClose={handleClose}
    >
      <img className="n" src="/nordletrans.png"></img>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Velkommen til Nordle! 🇳🇴 <br />
        <br />
        Du har 6 forsøk på å gjette deg fram til det riktige ordet. Etter hvert
        forsøk endrer fargen på bokstavene seg, dette viser hvor nært du er å
        finne dagens ord.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="correct"
        />
        <Cell value="O" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell value="S" isCompleted={true} />
        <Cell value="K" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Bokstaven N er en del av ordet og på riktig plass.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="S" isCompleted={true} />
        <Cell value="P" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="I"
          status="present"
        />
        <Cell value="L" isCompleted={true} />
        <Cell value="L" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Bokstaven I er i ordet, men er på feil plass.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="M" isCompleted={true} />
        <Cell value="O" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell isCompleted={true} value="R" />
        <Cell value="O" isRevealing={true} isCompleted={true} status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Bokstaven O er ikke en del av ordet på noen som helst plass.
      </p>

      <p className={creditsClass}>
        Oversatt og vedlikeholdt av{' '}
        <a href="mailto:magnus@wordle.fun" className="font-bold underline">
          Magnus
        </a>{' '}
        🤠
        <br /> Basert på{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
        >
          åpen kildekode
        </a>{' '}
      </p>
    </BaseModal>
  )
}
