export const VALID_GUESSES = [
  'syden',
  'abaya',
  'abbed',
  'abbor',
  'abort',
  'abrot',
  'adept',
  'aerob',
  'afasi',
  'aften',
  'agape',
  'agave',
  'agens',
  'agent',
  'agere',
  'agnat',
  'agnor',
  'agoni',
  'agrar',
  'kaker',
  'agurk',
  'aioli',
  'akkar',
  'akryl',
  'aksel',
  'aksje',
  'aksle',
  'akson',
  'akter',
  'aktet',
  'aktiv',
  'aktor',
  'aktre',
  'aktør',
  'akutt',
  'alali',
  'alant',
  'alarm',
  'albin',
  'albue',
  'album',
  'alder',
  'aldre',
  'aldri',
  'alene',
  'aleut',
  'alias',
  'alibi',
  'alkan',
  'alken',
  'alkis',
  'alkyd',
  'alkyl',
  'alkyn',
  'allel',
  'aller',
  'alpin',
  'altan',
  'alter',
  'altså',
  'alved',
  'alvor',
  'ambar',
  'ambra',
  'amcar',
  'amorf',
  'ampel',
  'amper',
  'amøbe',
  'anbud',
  'andel',
  'andra',
  'andre',
  'anemi',
  'angel',
  'anger',
  'angle',
  'angre',
  'angst',
  'aning',
  'anion',
  'ankel',
  'anker',
  'ankre',
  'anløp',
  'annal',
  'annen',
  'anode',
  'anrop',
  'anslå',
  'anstå',
  'aorta',
  'apati',
  'aperi',
  'aphel',
  'april',
  'apsis',
  'areal',
  'arena',
  'argon',
  'argot',
  'arier',
  'arild',
  'arisk',
  'arkiv',
  'armod',
  'aroma',
  'arret',
  'arrig',
  'arsen',
  'artig',
  'asete',
  'asiat',
  'asket',
  'aspik',
  'astat',
  'astma',
  'atlas',
  'atlet',
  'atoll',
  'atten',
  'atter',
  'attpå',
  'attre',
  'attrå',
  'attåt',
  'atypi',
  'augur',
  'auret',
  'austa',
  'avart',
  'avbud',
  'avdal',
  'avdra',
  'avdød',
  'aveny',
  'avers',
  'avgud',
  'avhøl',
  'avhør',
  'avind',
  'avise',
  'avkle',
  'avkok',
  'avkom',
  'avlat',
  'avler',
  'avlyd',
  'avløp',
  'avrop',
  'avsig',
  'avsky',
  'avslå',
  'avstå',
  'avsug',
  'avsvi',
  'avtak',
  'avund',
  'avveg',
  'avvei',
  'avvik',
  'babel',
  'bable',
  'bacon',
  'bader',
  'bagge',
  'bahai',
  'bajas',
  'baker',
  'bakke',
  'bakli',
  'bakol',
  'bakom',
  'bakpå',
  'bakre',
  'bakse',
  'bakst',
  'bakut',
  'balje',
  'balle',
  'balsa',
  'bamse',
  'banal',
  'banan',
  'bande',
  'bandy',
  'bange',
  'banjo',
  'banke',
  'banne',
  'bantu',
  'barde',
  'barka',
  'barke',
  'baron',
  'barre',
  'barsk',
  'basal',
  'basar',
  'basis',
  'baske',
  'basme',
  'basse',
  'basta',
  'baste',
  'basun',
  'batch',
  'bauta',
  'baute',
  'bayer',
  'bebop',
  'bedra',
  'bedre',
  'befal',
  'befri',
  'beger',
  'begge',
  'beglo',
  'behag',
  'behov',
  'beige',
  'beile',
  'beine',
  'being',
  'beise',
  'beisk',
  'beist',
  'beite',
  'bejae',
  'bekle',
  'belje',
  'belme',
  'belte',
  'beløp',
  'bemba',
  'bende',
  'benet',
  'benke',
  'benzo',
  'beret',
  'berge',
  'berme',
  'berte',
  'beslå',
  'bestå',
  'besyv',
  'besøk',
  'betel',
  'betle',
  'betre',
  'betro',
  'beven',
  'bever',
  'bevis',
  'bevre',
  'beære',
  'biavl',
  'bibel',
  'bidig',
  'bidra',
  'bielv',
  'bifil',
  'bikke',
  'bikse',
  'bikte',
  'bilag',
  'bilde',
  'bilkø',
  'bille',
  'bimbo',
  'binde',
  'binge',
  'bingo',
  'binne',
  'binom',
  'binær',
  'bisak',
  'bisam',
  'bisle',
  'bisol',
  'bison',
  'bistå',
  'bitte',
  'bjeff',
  'bjerk',
  'bjørk',
  'bjørn',
  'blaff',
  'blakk',
  'blank',
  'blant',
  'blass',
  'blaut',
  'bleie',
  'bleig',
  'bleik',
  'bleke',
  'blekk',
  'blest',
  'blikk',
  'blind',
  'bling',
  'blink',
  'blits',
  'blive',
  'blogg',
  'blokk',
  'blond',
  'blote',
  'blott',
  'blues',
  'blund',
  'blunk',
  'bluse',
  'bluss',
  'blåis',
  'blåne',
  'blåse',
  'blåst',
  'blått',
  'blære',
  'bløff',
  'bløte',
  'bløyt',
  'bobil',
  'bobin',
  'boble',
  'bogey',
  'boggi',
  'bogne',
  'bohem',
  'bojar',
  'bokne',
  'bokse',
  'boler',
  'bolig',
  'bolle',
  'bolne',
  'bolte',
  'bombe',
  'bomme',
  'bonde',
  'bonus',
  'booke',
  'boots',
  'bopel',
  'borat',
  'borde',
  'borge',
  'borre',
  'borsj',
  'borte',
  'borti',
  'boson',
  'botid',
  'botne',
  'bowle',
  'bragd',
  'brake',
  'brakk',
  'brand',
  'brann',
  'brase',
  'brask',
  'brass',
  'brast',
  'bratt',
  'bravo',
  'bredd',
  'breie',
  'breis',
  'breke',
  'brekk',
  'brems',
  'brett',
  'brife',
  'brigg',
  'briks',
  'brink',
  'brisk',
  'brist',
  'brite',
  'brodd',
  'brokk',
  'brott',
  'brudd',
  'bruke',
  'brukt',
  'brune',
  'brunt',
  'bruse',
  'brusk',
  'brydd',
  'brygg',
  'bryne',
  'brysk',
  'bryst',
  'bryte',
  'bråke',
  'bråne',
  'bråte',
  'brøde',
  'brøle',
  'brønn',
  'brøyt',
  'bubil',
  'buden',
  'bufar',
  'buffe',
  'buffo',
  'buffé',
  'bufre',
  'bugne',
  'buing',
  'bukke',
  'bukle',
  'bukse',
  'bukte',
  'bulke',
  'bulle',
  'bulne',
  'bumse',
  'bunad',
  'bunke',
  'bunne',
  'bunte',
  'buorm',
  'burde',
  'burka',
  'burot',
  'buske',
  'busse',
  'buste',
  'butan',
  'butid',
  'butte',
  'bybud',
  'bydel',
  'byget',
  'bygge',
  'bykse',
  'byliv',
  'bylte',
  'bymur',
  'bymål',
  'bynær',
  'byrde',
  'byråd',
  'bysse',
  'byste',
  'bytte',
  'bytur',
  'byvis',
  'bærer',
  'bærår',
  'bæsje',
  'bølge',
  'bølle',
  'bønne',
  'børek',
  'børse',
  'børst',
  'bøsse',
  'bøtte',
  'bøyes',
  'bøyet',
  'bøyle',
  'campe',
  'cargo',
  'cashe',
  'caste',
  'celle',
  'cello',
  'chili',
  'chips',
  'choke',
  'chuck',
  'cirka',
  'coach',
  'conga',
  'corny',
  'cover',
  'covre',
  'crack',
  'crazy',
  'credo',
  'crepe',
  'cross',
  'curie',
  'curle',
  'cykel',
  'cyste',
  'dabbe',
  'dachs',
  'dadda',
  'dadle',
  'daffe',
  'dages',
  'dalbu',
  'daler',
  'dalte',
  'dampe',
  'dandy',
  'danke',
  'danna',
  'danne',
  'danse',
  'dansk',
  'darre',
  'daske',
  'dasse',
  'datid',
  'dativ',
  'datum',
  'daude',
  'davit',
  'debet',
  'debut',
  'decca',
  'degge',
  'deild',
  'deise',
  'deist',
  'deite',
  'dekan',
  'dekar',
  'dekke',
  'dekor',
  'delje',
  'delta',
  'demme',
  'demon',
  'dempe',
  'demre',
  'denar',
  'denge',
  'denim',
  'denne',
  'depot',
  'deppa',
  'deppe',
  'derav',
  'derby',
  'deres',
  'derom',
  'derpå',
  'derre',
  'desim',
  'desto',
  'dette',
  'devon',
  'diare',
  'diaré',
  'diett',
  'digel',
  'diger',
  'digge',
  'dikke',
  'dikte',
  'dildo',
  'dille',
  'dilte',
  'dimme',
  'dinar',
  'dingo',
  'dings',
  'diode',
  'dipol',
  'dirke',
  'dirre',
  'diset',
  'disig',
  'diske',
  'disko',
  'disse',
  'ditto',
  'divan',
  'djerv',
  'djupn',
  'dobbe',
  'doble',
  'dobro',
  'dodre',
  'dogge',
  'dogme',
  'dokke',
  'dolce',
  'dolke',
  'dolle',
  'domra',
  'donna',
  'donor',
  'doppe',
  'dorer',
  'dorge',
  'dorme',
  'dorsk',
  'doser',
  'doula',
  'doven',
  'dovne',
  'doyen',
  'dradd',
  'draft',
  'drage',
  'drake',
  'drakt',
  'drama',
  'drank',
  'draug',
  'dregg',
  'dreie',
  'dreis',
  'drene',
  'dreng',
  'drepe',
  'dress',
  'drett',
  'drift',
  'drikk',
  'driks',
  'drill',
  'drink',
  'drita',
  'drite',
  'dritt',
  'drive',
  'droge',
  'drone',
  'dropp',
  'drops',
  'drott',
  'druse',
  'dryge',
  'dryle',
  'drypp',
  'dryss',
  'dråpa',
  'dråpe',
  'drått',
  'drøle',
  'drønn',
  'drøse',
  'drøss',
  'drøye',
  'dubbe',
  'duble',
  'dublé',
  'duell',
  'duete',
  'duett',
  'dufte',
  'dugge',
  'dukat',
  'dukke',
  'dulgt',
  'dulle',
  'dulme',
  'dulpe',
  'dulte',
  'dumme',
  'dummy',
  'dumpe',
  'dunet',
  'dunge',
  'dunke',
  'dunst',
  'duppe',
  'durra',
  'dusin',
  'dusje',
  'duske',
  'duste',
  'dusør',
  'dvale',
  'dvask',
  'dveil',
  'dvele',
  'dverg',
  'dyade',
  'dybde',
  'dydig',
  'dykke',
  'dylle',
  'dynge',
  'dynke',
  'dyppe',
  'dypål',
  'dyrke',
  'dysse',
  'dytte',
  'dyvåt',
  'dådyr',
  'dåset',
  'dæven',
  'dølge',
  'dølsk',
  'dømme',
  'dønne',
  'døper',
  'døsen',
  'døsig',
  'døyve',
  'eagle',
  'ebola',
  'edder',
  'eddik',
  'edikt',
  'egdsk',
  'eglet',
  'egnet',
  'eider',
  'eigen',
  'einer',
  'eiret',
  'eitel',
  'eiter',
  'ekall',
  'ekkel',
  'ekorn',
  'eksem',
  'eksil',
  'eksos',
  'eland',
  'elbil',
  'eldes',
  'eldre',
  'eldst',
  'elegi',
  'elgku',
  'eling',
  'elite',
  'eller',
  'ellok',
  'eloge',
  'elske',
  'emmen',
  'emoji',
  'endiv',
  'endog',
  'endre',
  'engel',
  'enhet',
  'ening',
  'enkel',
  'enket',
  'enkom',
  'enkét',
  'enorm',
  'enrom',
  'ensom',
  'enten',
  'entre',
  'entré',
  'envis',
  'enzym',
  'enøre',
  'enøyd',
  'epikk',
  'episk',
  'epoke',
  'ergre',
  'erten',
  'ertet',
  'ertog',
  'esing',
  'esker',
  'espri',
  'essay',
  'ester',
  'estet',
  'etikk',
  'eting',
  'etisk',
  'etter',
  'ettid',
  'etyde',
  'event',
  'fabel',
  'fable',
  'facts',
  'fader',
  'fager',
  'fagne',
  'faite',
  'fakir',
  'fakke',
  'fakle',
  'fakse',
  'fakte',
  'falby',
  'falde',
  'falle',
  'falme',
  'FALSE',
  'falsk',
  'famle',
  'famøs',
  'fancy',
  'fange',
  'farad',
  'farao',
  'farga',
  'farge',
  'farin',
  'farse',
  'farsi',
  'farte',
  'fasan',
  'fasit',
  'fasle',
  'faste',
  'fatal',
  'fatle',
  'fatta',
  'fatte',
  'fatum',
  'fatwa',
  'fatøl',
  'fauke',
  'fauna',
  'favne',
  'favør',
  'feavl',
  'feber',
  'fecit',
  'fedme',
  'fefot',
  'feide',
  'feier',
  'feigd',
  'feike',
  'feile',
  'feire',
  'feite',
  'fekte',
  'felle',
  'felås',
  'femte',
  'femti',
  'fenad',
  'fenge',
  'fenol',
  'ferde',
  'ferge',
  'ferie',
  'ferje',
  'ferme',
  'fersk',
  'fesjå',
  'feste',
  'fetne',
  'fiber',
  'fidus',
  'fiffe',
  'fight',
  'figur',
  'fiken',
  'fikle',
  'fikse',
  'filet',
  'fille',
  'filme',
  'filur',
  'finer',
  'finis',
  'finke',
  'finne',
  'finsk',
  'finte',
  'firer',
  'firma',
  'fiske',
  'fisle',
  'fiste',
  'fjase',
  'fjelg',
  'fjell',
  'fjern',
  'fjert',
  'fjesk',
  'fjoll',
  'fjols',
  'fjomp',
  'fjong',
  'fjord',
  'fjott',
  'fjusk',
  'fjåse',
  'fjære',
  'fjøre',
  'flabb',
  'flage',
  'flagg',
  'flake',
  'flaks',
  'flaps',
  'flare',
  'flash',
  'flask',
  'flass',
  'flate',
  'flaue',
  'fleip',
  'fleis',
  'flekk',
  'fleng',
  'flens',
  'flere',
  'flesk',
  'flest',
  'flidd',
  'flikk',
  'flink',
  'flint',
  'flipp',
  'flire',
  'flise',
  'floke',
  'flokk',
  'floks',
  'flopp',
  'flora',
  'floss',
  'flott',
  'fluid',
  'fluks',
  'flukt',
  'fluor',
  'flush',
  'flusk',
  'fluss',
  'flust',
  'flyge',
  'flyte',
  'flåer',
  'flåse',
  'flåte',
  'flått',
  'flære',
  'fløke',
  'flørt',
  'fløte',
  'fløyt',
  'fnatt',
  'fnise',
  'fnokk',
  'fnugg',
  'fnyse',
  'foaje',
  'foajé',
  'fokal',
  'fokus',
  'folat',
  'folde',
  'folie',
  'folio',
  'folke',
  'folle',
  'folne',
  'fomle',
  'fondy',
  'fonem',
  'fonne',
  'foran',
  'forbi',
  'forby',
  'fordi',
  'forgi',
  'forgå',
  'forme',
  'formå',
  'forre',
  'forse',
  'forta',
  'forte',
  'forum',
  'forut',
  'forår',
  'fosse',
  'foton',
  'frabe',
  'fragå',
  'frakk',
  'frakt',
  'franc',
  'frank',
  'frarå',
  'frase',
  'frasi',
  'frata',
  'freda',
  'frede',
  'frege',
  'frekk',
  'freon',
  'frese',
  'friby',
  'frier',
  'frigi',
  'frise',
  'frisk',
  'frist',
  'frita',
  'friår',
  'front',
  'frosk',
  'frost',
  'frukt',
  'fryde',
  'frykt',
  'fryse',
  'fråde',
  'frøse',
  'frøår',
  'fukte',
  'funke',
  'furer',
  'furet',
  'furie',
  'furte',
  'fusel',
  'fuske',
  'futil',
  'futte',
  'fylde',
  'fylke',
  'fylle',
  'fymat',
  'fyord',
  'fyrig',
  'fyrop',
  'fyrst',
  'fysak',
  'fysen',
  'fåret',
  'fælen',
  'færre',
  'fødes',
  'føler',
  'følge',
  'følle',
  'føner',
  'fører',
  'først',
  'førti',
  'føyke',
  'føyse',
  'gafle',
  'gagne',
  'gagns',
  'gaide',
  'gakke',
  'galei',
  'galen',
  'galge',
  'galla',
  'galle',
  'galon',
  'galte',
  'gaman',
  'gambe',
  'gamer',
  'gamet',
  'gamla',
  'gamma',
  'gamme',
  'gampe',
  'ganda',
  'gande',
  'gange',
  'gante',
  'gapen',
  'gapet',
  'gaple',
  'garde',
  'garne',
  'garpe',
  'garva',
  'garve',
  'gasje',
  'gasse',
  'gasta',
  'gauda',
  'gauke',
  'gaule',
  'gaume',
  'gaupe',
  'gebet',
  'gebyr',
  'gehør',
  'geipe',
  'geite',
  'gekko',
  'gemal',
  'gemen',
  'gemme',
  'gemse',
  'genom',
  'genre',
  'genus',
  'getto',
  'gevir',
  'gevær',
  'gidde',
  'gifte',
  'gigge',
  'gilde',
  'gilje',
  'gimpe',
  'gipse',
  'gisne',
  'gispe',
  'gisse',
  'gitar',
  'giver',
  'gjall',
  'gjekk',
  'gjeld',
  'gjeng',
  'gjerd',
  'gjesp',
  'gjest',
  'gjete',
  'gjord',
  'gjure',
  'gjære',
  'gjøde',
  'gjøgl',
  'gjøle',
  'gjøne',
  'gjøre',
  'gjørs',
  'glace',
  'glacé',
  'glafs',
  'glane',
  'glans',
  'glass',
  'glatt',
  'glede',
  'glefs',
  'glepp',
  'glett',
  'glide',
  'glima',
  'glime',
  'glimt',
  'glipe',
  'glipp',
  'glire',
  'glise',
  'glore',
  'glose',
  'glugg',
  'gløde',
  'gløgg',
  'gløtt',
  'gnage',
  'gneis',
  'gnell',
  'gnien',
  'gnier',
  'gnirk',
  'gniss',
  'gnist',
  'gnome',
  'gnure',
  'gnåle',
  'godta',
  'godte',
  'gombe',
  'gomle',
  'gomme',
  'gonzo',
  'gople',
  'goter',
  'gouda',
  'grabb',
  'grade',
  'grand',
  'grann',
  'graps',
  'grase',
  'graut',
  'grave',
  'green',
  'greie',
  'grein',
  'greip',
  'grell',
  'grend',
  'grene',
  'grepa',
  'gresk',
  'gress',
  'greve',
  'gribb',
  'griff',
  'grill',
  'grime',
  'grind',
  'grine',
  'gripe',
  'grise',
  'grisk',
  'grogg',
  'grope',
  'gross',
  'gruff',
  'grugg',
  'grukk',
  'grums',
  'grunk',
  'grunn',
  'gruse',
  'gruve',
  'gryle',
  'gryne',
  'grynt',
  'gryte',
  'gråle',
  'gråne',
  'gråor',
  'gråte',
  'grått',
  'grøde',
  'grøft',
  'grønn',
  'grønt',
  'grøpp',
  'grøss',
  'grøte',
  'guano',
  'gubbe',
  'guffe',
  'gufse',
  'guide',
  'gulke',
  'gulne',
  'gulpe',
  'gummi',
  'gunst',
  'gurpe',
  'guste',
  'gydje',
  'gyger',
  'gylle',
  'gylte',
  'gymme',
  'gynge',
  'gyser',
  'gytje',
  'gyvel',
  'gåing',
  'gåtur',
  'gæler',
  'gæren',
  'gøyal',
  'gøyse',
  'habil',
  'hacke',
  'hadsj',
  'hagel',
  'hagle',
  'haike',
  'haiku',
  'hakka',
  'hakke',
  'halal',
  'halle',
  'hallo',
  'halma',
  'halse',
  'halte',
  'halve',
  'halvt',
  'hambo',
  'hamle',
  'hamne',
  'hamre',
  'hamse',
  'hands',
  'hanke',
  'hansa',
  'haram',
  'harem',
  'harke',
  'harme',
  'harpe',
  'harpy',
  'harry',
  'harsk',
  'harve',
  'haspe',
  'haste',
  'hater',
  'hatig',
  'hatsk',
  'hauge',
  'hauke',
  'hauld',
  'hausa',
  'havis',
  'havne',
  'havre',
  'havål',
  'heade',
  'hedde',
  'heden',
  'heder',
  'hedre',
  'hefta',
  'hefte',
  'hegde',
  'hegne',
  'hegre',
  'heilo',
  'heime',
  'heine',
  'heise',
  'heiti',
  'hekke',
  'hekle',
  'hekse',
  'hekte',
  'hekto',
  'helde',
  'heler',
  'helle',
  'helot',
  'helse',
  'helst',
  'helvt',
  'hemme',
  'hempe',
  'hende',
  'hendt',
  'henge',
  'hengi',
  'henna',
  'henne',
  'henry',
  'hente',
  'heppe',
  'herav',
  'herda',
  'herde',
  'herja',
  'herje',
  'herke',
  'herme',
  'herom',
  'heros',
  'herpe',
  'herpå',
  'herre',
  'herse',
  'hertz',
  'hesje',
  'hespe',
  'hetne',
  'hetse',
  'hette',
  'hevde',
  'hevle',
  'hevne',
  'higen',
  'hijab',
  'hikke',
  'hikst',
  'hilde',
  'hilse',
  'himla',
  'himle',
  'hindi',
  'hindu',
  'hinke',
  'hinne',
  'hinte',
  'hiren',
  'hirse',
  'hisse',
  'hitre',
  'hitte',
  'hjalt',
  'hjell',
  'hjelm',
  'hjelp',
  'hjord',
  'hjort',
  'hjupe',
  'hobby',
  'hocke',
  'hodde',
  'hofte',
  'hogge',
  'hogst',
  'holde',
  'holdt',
  'holke',
  'holme',
  'holne',
  'homme',
  'hoppe',
  'horde',
  'horet',
  'horse',
  'horst',
  'hoste',
  'house',
  'hovde',
  'hovel',
  'hoven',
  'hovne',
  'hubro',
  'hufse',
  'hugal',
  'hugge',
  'hugst',
  'hugta',
  'hukre',
  'hulke',
  'hulle',
  'human',
  'humid',
  'humle',
  'humor',
  'humpe',
  'humre',
  'humul',
  'humus',
  'humør',
  'huner',
  'hurpe',
  'hurra',
  'husar',
  'huske',
  'husky',
  'husly',
  'hutle',
  'hutre',
  'hvass',
  'hvelv',
  'hvese',
  'hvete',
  'hvile',
  'hvine',
  'hvisk',
  'hvite',
  'hvitt',
  'hybel',
  'hydra',
  'hyene',
  'hygge',
  'hykle',
  'hylle',
  'hylse',
  'hymen',
  'hymne',
  'hynde',
  'hynne',
  'hyppe',
  'hyrde',
  'hysje',
  'hysse',
  'hytte',
  'hålke',
  'hånle',
  'hånsk',
  'håret',
  'hærta',
  'høgde',
  'høgre',
  'høker',
  'høkre',
  'hølet',
  'hølje',
  'hølke',
  'høres',
  'høste',
  'høvel',
  'høvle',
  'høvre',
  'høybu',
  'høyde',
  'høymo',
  'høyne',
  'høyre',
  'høyst',
  'icing',
  'ideal',
  'idest',
  'idiom',
  'idiot',
  'idyll',
  'iføre',
  'igjen',
  'iguan',
  'ihuga',
  'ilbud',
  'ilder',
  'ildne',
  'ilegg',
  'iling',
  'ilske',
  'ilter',
  'image',
  'imago',
  'imens',
  'immun',
  'inder',
  'indie',
  'indre',
  'inert',
  'infam',
  'infus',
  'ingen',
  'innad',
  'innbo',
  'innbu',
  'innby',
  'innen',
  'inngi',
  'inngå',
  'innom',
  'innpå',
  'innse',
  'innta',
  'innåt',
  'input',
  'intet',
  'intim',
  'intro',
  'irisk',
  'ironi',
  'irret',
  'isand',
  'isbar',
  'isbit',
  'isblå',
  'isbor',
  'isbre',
  'isete',
  'isfri',
  'ishav',
  'ishud',
  'ishus',
  'ising',
  'islag',
  'islam',
  'islom',
  'isnål',
  'ispil',
  'isres',
  'ister',
  'istid',
  'isøde',
  'isøks',
  'ivrig',
  'jabbe',
  'jafse',
  'jager',
  'jaggu',
  'jakke',
  'jakku',
  'jakte',
  'jalla',
  'jalme',
  'jamat',
  'jambe',
  'jamme',
  'jamne',
  'jamre',
  'jaord',
  'japse',
  'jaske',
  'jasse',
  'jatte',
  'jazze',
  'jeans',
  'jeger',
  'jekke',
  'jelke',
  'jemte',
  'jenka',
  'jenke',
  'jente',
  'jerpe',
  'jette',
  'jevne',
  'jibbe',
  'jihad',
  'jippo',
  'jobbe',
  'jodid',
  'jodle',
  'jogge',
  'joggu',
  'joike',
  'joker',
  'jolle',
  'joner',
  'jorde',
  'jorte',
  'jotun',
  'joule',
  'jubel',
  'juble',
  'judas',
  'jugle',
  'juice',
  'jukke',
  'jukse',
  'jumbo',
  'jumpe',
  'junta',
  'jutul',
  'juvel',
  'jålet',
  'jækel',
  'jækla',
  'jærbu',
  'jærsk',
  'jævel',
  'jævla',
  'jøkel',
  'kabal',
  'kabbe',
  'kabel',
  'kabin',
  'kable',
  'kabyl',
  'kader',
  'kaffe',
  'kafir',
  'kagge',
  'kajal',
  'kakao',
  'kakke',
  'kakle',
  'kakse',
  'kalas',
  'kalif',
  'kalke',
  'kalle',
  'kalun',
  'kalve',
  'kamba',
  'kamel',
  'kamin',
  'kamme',
  'kanak',
  'kanal',
  'kanel',
  'kanin',
  'kanne',
  'kanon',
  'kante',
  'kaper',
  'kapok',
  'kappe',
  'kapre',
  'kapun',
  'karat',
  'karbo',
  'karde',
  'karen',
  'karet',
  'kargo',
  'karib',
  'karma',
  'karpe',
  'karre',
  'karri',
  'karré',
  'karse',
  'karsk',
  'karte',
  'karve',
  'kasko',
  'kasse',
  'kaste',
  'kasus',
  'katar',
  'katet',
  'katte',
  'kauke',
  'kause',
  'kavai',
  'kavle',
  'kebab',
  'kediv',
  'kefir',
  'kegel',
  'keike',
  'keive',
  'kelim',
  'kendo',
  'keram',
  'keton',
  'khmer',
  'kibbe',
  'kiker',
  'kikke',
  'kikne',
  'kikse',
  'kilde',
  'kilen',
  'kiler',
  'kille',
  'kimse',
  'kinin',
  'kinky',
  'kinne',
  'kiosk',
  'kipen',
  'kiper',
  'kippe',
  'kirke',
  'kisel',
  'kisle',
  'kiste',
  'kiter',
  'kitin',
  'kitle',
  'kitsj',
  'kitte',
  'kives',
  'kjake',
  'kjaks',
  'kjapp',
  'kjase',
  'kjede',
  'kjeft',
  'kjekk',
  'kjeks',
  'kjele',
  'kjemi',
  'kjeng',
  'kjent',
  'kjepp',
  'kjerr',
  'kjerv',
  'kjese',
  'kjesk',
  'kjeve',
  'kjoks',
  'kjole',
  'kjone',
  'kjuke',
  'kjæle',
  'kjære',
  'kjæte',
  'kjøld',
  'kjøle',
  'kjømd',
  'kjønn',
  'kjøpe',
  'kjøre',
  'kjørt',
  'kjøtt',
  'kjøve',
  'klabb',
  'kladd',
  'klaff',
  'klage',
  'klake',
  'klakk',
  'klamp',
  'klang',
  'klant',
  'klapp',
  'klaps',
  'klare',
  'klase',
  'klask',
  'klatt',
  'klauv',
  'klave',
  'klebe',
  'klede',
  'klegg',
  'kleie',
  'kleim',
  'klein',
  'kleis',
  'kleiv',
  'klekk',
  'klemt',
  'klepp',
  'klerk',
  'kless',
  'klett',
  'kleve',
  'klikk',
  'klima',
  'kline',
  'kling',
  'klink',
  'klipe',
  'klipp',
  'klips',
  'klirr',
  'kliss',
  'klitt',
  'klive',
  'klode',
  'klone',
  'klopp',
  'klore',
  'kloss',
  'klove',
  'klovn',
  'klubb',
  'klukk',
  'klump',
  'klums',
  'klunk',
  'klure',
  'kluss',
  'klynk',
  'klype',
  'klyse',
  'klyss',
  'klyve',
  'klåte',
  'kløft',
  'kløkk',
  'kløkt',
  'kløne',
  'kløve',
  'kløyv',
  'knabb',
  'knagg',
  'knake',
  'knakk',
  'knall',
  'knapp',
  'knapt',
  'knark',
  'knarr',
  'knart',
  'knase',
  'knask',
  'knast',
  'knatt',
  'knaus',
  'knege',
  'knegg',
  'knegå',
  'kneik',
  'knekk',
  'knekt',
  'knele',
  'knepp',
  'knert',
  'knest',
  'knett',
  'kniks',
  'knipe',
  'knips',
  'knirk',
  'knise',
  'knive',
  'knoge',
  'knoke',
  'knoll',
  'knope',
  'knopp',
  'knort',
  'knote',
  'knott',
  'knubb',
  'knuge',
  'knull',
  'knupp',
  'knurr',
  'knurv',
  'knuse',
  'knusk',
  'knute',
  'knutt',
  'knuvl',
  'knyst',
  'knyte',
  'knøtt',
  'koala',
  'kobbe',
  'koble',
  'kobra',
  'kodde',
  'koder',
  'kofte',
  'kogge',
  'kogle',
  'koine',
  'koiné',
  'koker',
  'kokke',
  'kokos',
  'kokre',
  'kolbe',
  'kolje',
  'kolle',
  'kolli',
  'kolon',
  'komet',
  'komma',
  'komme',
  'kompe',
  'komse',
  'konge',
  'kongo',
  'konke',
  'konse',
  'konte',
  'konto',
  'konus',
  'kopal',
  'kopek',
  'kopen',
  'kople',
  'koppe',
  'kopra',
  'koral',
  'koran',
  'korde',
  'korka',
  'korke',
  'korne',
  'korny',
  'korps',
  'korse',
  'korte',
  'koste',
  'kovne',
  'kraal',
  'krafs',
  'kraft',
  'krage',
  'kragg',
  'krake',
  'krakk',
  'krank',
  'krans',
  'krapp',
  'krase',
  'krasj',
  'krasl',
  'krass',
  'kratt',
  'krave',
  'kravl',
  'kredd',
  'kreft',
  'kreke',
  'krele',
  'kremt',
  'kreol',
  'krepp',
  'kreps',
  'kreti',
  'krets',
  'kreve',
  'krige',
  'krill',
  'krime',
  'kring',
  'krins',
  'krise',
  'krite',
  'kritt',
  'kroat',
  'kroke',
  'kroki',
  'krone',
  'kropp',
  'krote',
  'krugg',
  'kruke',
  'krull',
  'krupp',
  'kruse',
  'krutt',
  'krymp',
  'kryne',
  'krype',
  'krypt',
  'kryss',
  'kråke',
  'kråle',
  'krøke',
  'krøll',
  'kubbe',
  'kuben',
  'kubus',
  'kudos',
  'kufor',
  'kufte',
  'kufôr',
  'kuhud',
  'kujon',
  'kujur',
  'kulde',
  'kulle',
  'kulse',
  'kulte',
  'kulør',
  'kumle',
  'kumme',
  'kumpe',
  'kunde',
  'kunne',
  'kunst',
  'kuple',
  'kuppe',
  'kurer',
  'kurie',
  'kurle',
  'kurre',
  'kurse',
  'kurve',
  'kuske',
  'kusma',
  'kuste',
  'kutte',
  'kuven',
  'kuvet',
  'kuøye',
  'kvabb',
  'kvakk',
  'kvalm',
  'kvann',
  'kvant',
  'kvare',
  'kvarg',
  'kvark',
  'kvart',
  'kvarv',
  'kvase',
  'kvasi',
  'kvass',
  'kvast',
  'kvede',
  'kvege',
  'kveik',
  'kveil',
  'kvein',
  'kveis',
  'kveke',
  'kvekk',
  'kveld',
  'kvele',
  'kvell',
  'kvepp',
  'kverk',
  'kvern',
  'kvide',
  'kvige',
  'kvike',
  'kvikk',
  'kvilt',
  'kvine',
  'kvink',
  'kvint',
  'kvise',
  'kviss',
  'kvist',
  'kvite',
  'kvitt',
  'kvote',
  'kvæve',
  'kylle',
  'kylne',
  'kynde',
  'kyrie',
  'kysse',
  'kårde',
  'kåren',
  'kåsør',
  'kødde',
  'kølet',
  'kølle',
  'kølos',
  'laban',
  'labbe',
  'laber',
  'labil',
  'laden',
  'lader',
  'lafte',
  'lager',
  'lagge',
  'lagje',
  'lagom',
  'lagre',
  'lakei',
  'laken',
  'lakke',
  'lalle',
  'lamme',
  'lampe',
  'lande',
  'lange',
  'langs',
  'langt',
  'lanke',
  'lanse',
  'lapis',
  'lappe',
  'largo',
  'larme',
  'larve',
  'laser',
  'laset',
  'laske',
  'lasso',
  'laste',
  'lasur',
  'laten',
  'latin',
  'lauge',
  'lauve',
  'lavvo',
  'lease',
  'leder',
  'ledes',
  'ledig',
  'lefle',
  'lefse',
  'legal',
  'legat',
  'legde',
  'legge',
  'legio',
  'leide',
  'leier',
  'leies',
  'leike',
  'leire',
  'leite',
  'leive',
  'leken',
  'lekke',
  'lekre',
  'lekse',
  'lekte',
  'lemen',
  'lemma',
  'lemme',
  'lempe',
  'lemur',
  'lemus',
  'lende',
  'lenge',
  'lengt',
  'lenke',
  'lense',
  'lepet',
  'lepje',
  'leppe',
  'lepra',
  'lerke',
  'leser',
  'leske',
  'lespe',
  'lesse',
  'letne',
  'lette',
  'leven',
  'lever',
  'levne',
  'levre',
  'ligge',
  'ligne',
  'likke',
  'likne',
  'likså',
  'liktå',
  'likør',
  'lilje',
  'lilla',
  'lille',
  'limbo',
  'linde',
  'lingo',
  'linje',
  'links',
  'linne',
  'linse',
  'lipid',
  'lirke',
  'lisse',
  'liste',
  'litas',
  'liten',
  'liter',
  'litot',
  'liung',
  'livat',
  'liver',
  'livne',
  'livre',
  'livré',
  'ljome',
  'ljore',
  'ljuge',
  'lobbe',
  'lobby',
  'lodde',
  'loden',
  'lodje',
  'loete',
  'loffe',
  'logge',
  'logre',
  'lojal',
  'lokal',
  'lokke',
  'lomme',
  'lompe',
  'lomre',
  'lomvi',
  'longe',
  'longs',
  'loope',
  'loppe',
  'loran',
  'lorje',
  'losje',
  'losji',
  'losse',
  'lotte',
  'lotto',
  'lotus',
  'lubbe',
  'lucia',
  'luffe',
  'lufte',
  'lugar',
  'lugge',
  'lugne',
  'lugom',
  'lugum',
  'lukke',
  'lukte',
  'lulle',
  'lumen',
  'lumpe',
  'lumsk',
  'lunde',
  'lunet',
  'lunge',
  'lunke',
  'lunne',
  'lunsj',
  'lunte',
  'lupin',
  'lupus',
  'lurke',
  'lurve',
  'lusen',
  'luset',
  'luske',
  'luten',
  'lutre',
  'lybsk',
  'lydig',
  'lykke',
  'lymfe',
  'lynne',
  'lynol',
  'lyske',
  'lysne',
  'lysol',
  'lyste',
  'lysår',
  'lytte',
  'låner',
  'lånta',
  'læger',
  'lærer',
  'lødig',
  'løfte',
  'løkke',
  'lønna',
  'lønne',
  'lønsk',
  'løper',
  'løpsk',
  'lørje',
  'løsen',
  'løsgi',
  'løsne',
  'løyen',
  'løyer',
  'løynd',
  'løyne',
  'løype',
  'løyse',
  'løyve',
  'macho',
  'madam',
  'mafia',
  'mager',
  'magma',
  'magre',
  'mahdi',
  'maile',
  'major',
  'makak',
  'maken',
  'makko',
  'makro',
  'maksi',
  'makte',
  'malay',
  'maler',
  'malje',
  'malle',
  'malme',
  'malte',
  'mamba',
  'mambo',
  'mamma',
  'maner',
  'manet',
  'mange',
  'mango',
  'manke',
  'manko',
  'manna',
  'manne',
  'mansk',
  'manus',
  'maori',
  'mappe',
  'marin',
  'marki',
  'marme',
  'marsj',
  'marsk',
  'masai',
  'maser',
  'maset',
  'maske',
  'masse',
  'maste',
  'matbu',
  'match',
  'mater',
  'matos',
  'matro',
  'matte',
  'maule',
  'maure',
  'medgi',
  'medgå',
  'medio',
  'meget',
  'megge',
  'megle',
  'meine',
  'meise',
  'meite',
  'mekka',
  'mekke',
  'mekle',
  'mekre',
  'melde',
  'melen',
  'melet',
  'melis',
  'melke',
  'melon',
  'melte',
  'mened',
  'menge',
  'menig',
  'mente',
  'merke',
  'merle',
  'mesan',
  'mesen',
  'meske',
  'meson',
  'messe',
  'metan',
  'meter',
  'metro',
  'mette',
  'metyl',
  'midje',
  'midle',
  'midte',
  'mikro',
  'mikse',
  'miljø',
  'mimre',
  'minke',
  'minne',
  'minst',
  'minus',
  'misse',
  'mista',
  'miste',
  'mitra',
  'mjaue',
  'mjele',
  'mjelt',
  'mjuke',
  'mjødm',
  'mjøle',
  'mjølk',
  'mjøll',
  'moare',
  'moaré',
  'mobbe',
  'mobil',
  'modal',
  'modem',
  'moden',
  'moder',
  'modig',
  'modne',
  'modul',
  'modus',
  'modøl',
  'mogop',
  'mogul',
  'mokka',
  'molbo',
  'molde',
  'molok',
  'molte',
  'molto',
  'mompe',
  'monne',
  'monom',
  'moped',
  'moppe',
  'moral',
  'morse',
  'morsk',
  'moset',
  'moske',
  'moské',
  'motiv',
  'motor',
  'motsi',
  'motta',
  'motto',
  'mudre',
  'muffe',
  'mufti',
  'mugge',
  'mugne',
  'mukke',
  'mulde',
  'mulig',
  'mulkt',
  'mulla',
  'mulle',
  'mulne',
  'multe',
  'mumie',
  'mumle',
  'mumse',
  'mungo',
  'munke',
  'munne',
  'murer',
  'murre',
  'musak',
  'muset',
  'musse',
  'mycel',
  'mygle',
  'mykis',
  'mykne',
  'mynde',
  'mynte',
  'myopi',
  'myose',
  'myrde',
  'myret',
  'myrra',
  'myske',
  'mysli',
  'måler',
  'måned',
  'måtte',
  'møbel',
  'mødig',
  'mødom',
  'møkka',
  'møkke',
  'mølje',
  'mølle',
  'mønje',
  'mørje',
  'mørke',
  'mørne',
  'møtes',
  'nabbe',
  'nadir',
  'nafse',
  'nafta',
  'nagle',
  'naken',
  'nakke',
  'nappa',
  'nappe',
  'narko',
  'narre',
  'nasal',
  'naske',
  'natte',
  'natur',
  'naube',
  'naust',
  'navar',
  'naver',
  'navle',
  'navne',
  'nebbe',
  'nedad',
  'nedom',
  'nedpå',
  'nedre',
  'neigu',
  'nekte',
  'nemme',
  'nemnd',
  'nenne',
  'neppe',
  'nerts',
  'nerve',
  'nesbu',
  'nesle',
  'neste',
  'nette',
  'netto',
  'never',
  'nevne',
  'nibbe',
  'nidel',
  'nidsk',
  'niese',
  'nikab',
  'nikke',
  'ninja',
  'nippe',
  'nisje',
  'nisse',
  'niste',
  'niten',
  'nitid',
  'nitte',
  'nitti',
  'niårs',
  'niøye',
  'nobel',
  'nodul',
  'nogen',
  'nokke',
  'nokså',
  'nomen',
  'nonne',
  'norda',
  'nordi',
  'norne',
  'norsk',
  'notar',
  'notat',
  'notis',
  'nubbe',
  'nudde',
  'nudel',
  'nufse',
  'nugat',
  'nugge',
  'nulle',
  'nulte',
  'nuppe',
  'nuske',
  'nusle',
  'nusse',
  'nuvet',
  'nybær',
  'nygle',
  'nyhet',
  'nying',
  'nykke',
  'nylig',
  'nylon',
  'nymfe',
  'nynne',
  'nyord',
  'nyrik',
  'nysnø',
  'nyter',
  'nytid',
  'nytte',
  'nyvle',
  'nådig',
  'nålys',
  'nåtid',
  'nåtle',
  'nåvel',
  'nærig',
  'nærme',
  'nødig',
  'nødår',
  'nøffe',
  'nøkke',
  'nøler',
  'nøste',
  'nøyes',
  'nøyte',
  'oasis',
  'oblat',
  'oblik',
  'odiøs',
  'offer',
  'ohmsk',
  'okapi',
  'oksid',
  'oktan',
  'oktav',
  'older',
  'oldis',
  'oljet',
  'olsok',
  'ombot',
  'ombud',
  'omega',
  'omegn',
  'omfar',
  'omgås',
  'omhug',
  'omlyd',
  'omløp',
  'omsut',
  'omsyn',
  'omveg',
  'omvei',
  'onani',
  'onkel',
  'onyks',
  'opart',
  'opera',
  'opiat',
  'opium',
  'oppad',
  'oppal',
  'oppby',
  'oppgi',
  'oppnå',
  'oppom',
  'oppta',
  'orden',
  'ordne',
  'ordre',
  'organ',
  'orgel',
  'orgie',
  'origo',
  'orkan',
  'orlog',
  'orlon',
  'orlov',
  'ornat',
  'oromo',
  'osean',
  'osman',
  'otium',
  'ottes',
  'ounce',
  'ovapå',
  'overs',
  'pacer',
  'padde',
  'padle',
  'paffe',
  'pagai',
  'pagan',
  'pakke',
  'pakte',
  'palme',
  'panda',
  'panel',
  'panne',
  'pante',
  'panty',
  'papir',
  'pappa',
  'pappe',
  'parat',
  'paria',
  'parti',
  'party',
  'pasja',
  'pasje',
  'passe',
  'passé',
  'pasta',
  'pater',
  'patos',
  'patte',
  'pauke',
  'pause',
  'pavet',
  'pedal',
  'peile',
  'peise',
  'peker',
  'pelle',
  'pelse',
  'penge',
  'penis',
  'penne',
  'penni',
  'penny',
  'pense',
  'pepre',
  'perle',
  'perse',
  'pesto',
  'petit',
  'petum',
  'piano',
  'piece',
  'piffe',
  'pigge',
  'pikke',
  'pilar',
  'pilke',
  'pille',
  'pilot',
  'pilte',
  'pimpe',
  'pinje',
  'pinke',
  'pinne',
  'pinse',
  'pinup',
  'pipet',
  'piple',
  'pippe',
  'pirat',
  'pirke',
  'pirog',
  'pirol',
  'pirre',
  'pirse',
  'pirum',
  'pirål',
  'piske',
  'pisse',
  'piste',
  'pitle',
  'pizza',
  'pjalt',
  'pjank',
  'pjatt',
  'pjokk',
  'pjusk',
  'plaff',
  'plage',
  'plagg',
  'plakk',
  'plane',
  'plank',
  'plask',
  'plass',
  'plast',
  'plate',
  'platt',
  'platå',
  'plebs',
  'pledd',
  'pleie',
  'plent',
  'pleti',
  'plett',
  'plikt',
  'pling',
  'plint',
  'plire',
  'plitt',
  'ploge',
  'plopp',
  'plott',
  'plugg',
  'plukk',
  'plump',
  'pluss',
  'plysj',
  'pløse',
  'pløye',
  'poeng',
  'poesi',
  'pokal',
  'poker',
  'pokre',
  'polar',
  'polio',
  'polis',
  'polka',
  'polsk',
  'polær',
  'pomor',
  'ponni',
  'poppe',
  'poret',
  'porno',
  'porto',
  'porøs',
  'posen',
  'poset',
  'poste',
  'posør',
  'potet',
  'potte',
  'praie',
  'prakk',
  'prakt',
  'prale',
  'prate',
  'prege',
  'preke',
  'prent',
  'press',
  'prest',
  'priar',
  'pride',
  'prikk',
  'prima',
  'prime',
  'primo',
  'prins',
  'prior',
  'prise',
  'proff',
  'promp',
  'propp',
  'props',
  'prosa',
  'prost',
  'prove',
  'provo',
  'prunk',
  'prupp',
  'prute',
  'pryde',
  'pryle',
  'prøve',
  'psyke',
  'ptroe',
  'pubes',
  'pudre',
  'puffe',
  'puger',
  'pugge',
  'pukka',
  'pukke',
  'pulje',
  'pulpa',
  'pulse',
  'pumpe',
  'pumps',
  'punch',
  'puner',
  'punge',
  'punkt',
  'punsj',
  'puppe',
  'purke',
  'purle',
  'purre',
  'puset',
  'pushe',
  'pusle',
  'pussa',
  'pusse',
  'puste',
  'putle',
  'putre',
  'putte',
  'pygme',
  'pygmé',
  'pynte',
  'pyore',
  'pyoré',
  'pysen',
  'pyset',
  'pyton',
  'påbud',
  'pådra',
  'påhør',
  'påske',
  'påstå',
  'påsyn',
  'påtår',
  'påver',
  'pælme',
  'pøbel',
  'pøble',
  'pølle',
  'pølse',
  'pønal',
  'pønse',
  'quart',
  'quilt',
  'quipu',
  'rabbe',
  'rabbi',
  'rabid',
  'rable',
  'racer',
  'radar',
  'radig',
  'radio',
  'radom',
  'radon',
  'rafse',
  'rafte',
  'ragge',
  'raide',
  'rakke',
  'rakle',
  'rakne',
  'rakst',
  'ralle',
  'rally',
  'ramle',
  'ramme',
  'rampe',
  'ramse',
  'ranch',
  'rande',
  'raner',
  'range',
  'ranke',
  'rapid',
  'rappe',
  'rapse',
  'raske',
  'rasle',
  'raspe',
  'raste',
  'ratio',
  'ratle',
  'ratsj',
  'ratte',
  'rauda',
  'rauke',
  'rause',
  'raute',
  'rayon',
  'rebus',
  'redde',
  'reder',
  'reell',
  'refse',
  'regel',
  'regle',
  'regne',
  'reike',
  'reile',
  'reint',
  'reipe',
  'reise',
  'reive',
  'rekel',
  'rekke',
  'rekst',
  'rekyl',
  'remis',
  'remje',
  'remse',
  'renke',
  'renne',
  'rense',
  'rensk',
  'rente',
  'repos',
  'repro',
  'reser',
  'resin',
  'retor',
  'rette',
  'retur',
  'revir',
  'revle',
  'revne',
  'ribbe',
  'rifle',
  'rigel',
  'rigge',
  'rigid',
  'rijal',
  'rikke',
  'rikle',
  'rikse',
  'rikte',
  'rille',
  'rimet',
  'ringe',
  'rinne',
  'ripet',
  'rippe',
  'riske',
  'risle',
  'rispe',
  'risse',
  'riste',
  'ritsj',
  'ritus',
  'rival',
  'robbe',
  'robot',
  'robåt',
  'rocka',
  'rocke',
  'rodel',
  'rodeo',
  'rokke',
  'rolig',
  'rolle',
  'roman',
  'rombe',
  'romer',
  'romme',
  'romsk',
  'rondo',
  'rorbu',
  'rosen',
  'roset',
  'rosin',
  'rosse',
  'roste',
  'rotet',
  'rotor',
  'rotte',
  'rouge',
  'rough',
  'rover',
  'rubbe',
  'rubel',
  'rubin',
  'rufse',
  'rugby',
  'rugde',
  'rugge',
  'rugle',
  'rukke',
  'rulle',
  'rulte',
  'rumba',
  'rumle',
  'rumpe',
  'runde',
  'rundt',
  'runge',
  'runke',
  'runne',
  'rural',
  'rushe',
  'ruske',
  'rusle',
  'ruste',
  'ruter',
  'rutet',
  'rutle',
  'rutte',
  'rydde',
  'ryger',
  'rygge',
  'rykke',
  'rykte',
  'rynke',
  'ryste',
  'rytme',
  'rådig',
  'rådyr',
  'råhet',
  'råmen',
  'råner',
  'rånet',
  'råske',
  'råtev',
  'råtne',
  'råvær',
  'rødme',
  'rødne',
  'røfle',
  'røfte',
  'røker',
  'røkte',
  'rølpe',
  'rømme',
  'rønne',
  'røret',
  'rørig',
  'røske',
  'røste',
  'røver',
  'røyke',
  'røynd',
  'røyne',
  'røyse',
  'røyte',
  'sabbe',
  'sabel',
  'sabla',
  'sable',
  'sadel',
  'sadle',
  'safir',
  'safte',
  'sagmo',
  'sahib',
  'saiga',
  'sakke',
  'sakne',
  'sakse',
  'sakte',
  'salat',
  'saldo',
  'salet',
  'salig',
  'salme',
  'salsa',
  'salte',
  'salto',
  'salve',
  'salær',
  'samba',
  'sambo',
  'sambu',
  'samle',
  'samme',
  'samrå',
  'samse',
  'sanke',
  'sankt',
  'sanne',
  'sanse',
  'sappe',
  'satan',
  'satse',
  'satyr',
  'saual',
  'sauna',
  'sause',
  'saute',
  'sauté',
  'savle',
  'savne',
  'scene',
  'scoop',
  'score',
  'sebra',
  'sedan',
  'sedat',
  'seder',
  'seede',
  'sefyr',
  'segle',
  'segne',
  'seide',
  'seier',
  'seife',
  'seile',
  'seipe',
  'seire',
  'seise',
  'sekel',
  'seksa',
  'sekst',
  'selen',
  'selge',
  'selje',
  'sella',
  'selle',
  'selot',
  'semin',
  'semje',
  'senat',
  'sende',
  'senet',
  'senil',
  'senit',
  'senke',
  'senna',
  'sepia',
  'sepoy',
  'seraf',
  'serai',
  'serie',
  'serum',
  'serve',
  'serøs',
  'sesam',
  'sesse',
  'seter',
  'setre',
  'sette',
  'sevje',
  'sexus',
  'señor',
  'sfære',
  'shake',
  'shelf',
  'showe',
  'shunt',
  'siden',
  'sider',
  'sigar',
  'sigen',
  'sigma',
  'signe',
  'sikle',
  'sikre',
  'sikte',
  'silde',
  'silke',
  'silur',
  'simle',
  'simpe',
  'sindi',
  'sinke',
  'sinna',
  'sinne',
  'sinus',
  'sioux',
  'sipet',
  'sippe',
  'sirat',
  'sirka',
  'sirup',
  'sisal',
  'sisel',
  'sisik',
  'sisle',
  'sitar',
  'sitat',
  'siter',
  'sitre',
  'sitte',
  'situp',
  'sivil',
  'sivåt',
  'sjakk',
  'sjako',
  'sjakt',
  'sjalu',
  'sjark',
  'sjarm',
  'sjask',
  'sjaue',
  'sjefe',
  'sjeik',
  'sjekk',
  'sjete',
  'sjeté',
  'sjikt',
  'sjoge',
  'sjogs',
  'sjokk',
  'sjona',
  'sjuer',
  'sjuke',
  'sjusk',
  'sjåke',
  'sjøbu',
  'sjøku',
  'skabb',
  'skade',
  'skaft',
  'skage',
  'skake',
  'skakk',
  'skala',
  'skald',
  'skalk',
  'skall',
  'skalp',
  'skank',
  'skann',
  'skant',
  'skape',
  'skare',
  'skark',
  'skarn',
  'skarp',
  'skarv',
  'skate',
  'skatt',
  'skaut',
  'skave',
  'skavl',
  'skeet',
  'skeid',
  'skeie',
  'skeis',
  'skeiv',
  'skift',
  'skikk',
  'skill',
  'skilt',
  'skimt',
  'skinn',
  'skipe',
  'skipp',
  'skita',
  'skite',
  'skitt',
  'skive',
  'skjen',
  'skjev',
  'skjor',
  'skjul',
  'skjær',
  'skjød',
  'skjør',
  'skjøt',
  'sklie',
  'skoft',
  'skokk',
  'skole',
  'skolm',
  'skolp',
  'skolt',
  'skort',
  'skose',
  'skott',
  'skove',
  'skovl',
  'skral',
  'skrap',
  'skred',
  'skrei',
  'skrev',
  'skrie',
  'skrik',
  'skrin',
  'skriv',
  'skrog',
  'skrot',
  'skrov',
  'skrud',
  'skrue',
  'skruv',
  'skryt',
  'skrål',
  'skrås',
  'skrøn',
  'skubb',
  'skudd',
  'skuff',
  'skule',
  'skulk',
  'skuls',
  'skump',
  'skunk',
  'skurd',
  'skure',
  'skurk',
  'skurv',
  'skute',
  'skutt',
  'skval',
  'skvip',
  'skvis',
  'skvær',
  'skyet',
  'skygg',
  'skyld',
  'skyll',
  'skyru',
  'skyss',
  'skyte',
  'skyts',
  'skyve',
  'skåle',
  'skåne',
  'skåre',
  'skåte',
  'skøye',
  'slabb',
  'sladd',
  'slafs',
  'slage',
  'slagg',
  'slags',
  'slakk',
  'slakt',
  'slamp',
  'slams',
  'slang',
  'slank',
  'slant',
  'slapp',
  'slaps',
  'slark',
  'slarv',
  'slask',
  'slaur',
  'slave',
  'slede',
  'sleid',
  'sleik',
  'sleip',
  'sleis',
  'sleiv',
  'slekt',
  'sleng',
  'slepe',
  'slepp',
  'slesk',
  'slett',
  'sleve',
  'slide',
  'slikk',
  'slime',
  'slind',
  'slipe',
  'slipp',
  'slips',
  'slire',
  'slisk',
  'slite',
  'slitt',
  'sliul',
  'slodd',
  'slott',
  'sludd',
  'slufs',
  'sluke',
  'slukt',
  'slump',
  'slums',
  'slupp',
  'slure',
  'slurk',
  'slurp',
  'slurv',
  'sluse',
  'slusk',
  'slutt',
  'slyet',
  'slyng',
  'slåpe',
  'slåss',
  'slått',
  'sløke',
  'sløre',
  'sløse',
  'sløve',
  'sløyd',
  'sløye',
  'smake',
  'smale',
  'smart',
  'smash',
  'smask',
  'smatt',
  'smede',
  'smeik',
  'smeis',
  'smekk',
  'smell',
  'smikk',
  'smile',
  'smisk',
  'smitt',
  'smokk',
  'smolt',
  'smukk',
  'smule',
  'smult',
  'smuss',
  'smutt',
  'smyge',
  'smyle',
  'småby',
  'småen',
  'småfe',
  'smøre',
  'smøye',
  'snabb',
  'snadd',
  'snafs',
  'snake',
  'snakk',
  'snaks',
  'snapp',
  'snaps',
  'snare',
  'snarp',
  'snart',
  'snask',
  'snaue',
  'snavl',
  'snegl',
  'sneie',
  'sneip',
  'sneis',
  'sneke',
  'snerk',
  'snerp',
  'snerr',
  'snert',
  'snike',
  'snile',
  'snill',
  'snipe',
  'snipp',
  'snitt',
  'snive',
  'snobb',
  'snoke',
  'snope',
  'snork',
  'snufs',
  'snuft',
  'snupp',
  'snupt',
  'snurp',
  'snurr',
  'snurt',
  'snuse',
  'snusk',
  'snute',
  'snutt',
  'snyta',
  'snyte',
  'snytt',
  'snåle',
  'snåve',
  'snøet',
  'snøft',
  'snøgg',
  'snøis',
  'snøre',
  'snørr',
  'snøse',
  'snøvl',
  'snøye',
  'snøår',
  'soare',
  'soaré',
  'sobel',
  'sober',
  'sogne',
  'sokke',
  'sokne',
  'solar',
  'solid',
  'solur',
  'solår',
  'somle',
  'somme',
  'sonar',
  'sonde',
  'sonor',
  'soper',
  'soppe',
  'sorbe',
  'sorbé',
  'sorti',
  'sotet',
  'sotho',
  'sound',
  'sovne',
  'spade',
  'spane',
  'spann',
  'spant',
  'spare',
  'spark',
  'spase',
  'spatt',
  'speed',
  'speil',
  'speke',
  'spekk',
  'speks',
  'spell',
  'spelt',
  'spene',
  'spenn',
  'spent',
  'spett',
  'spidd',
  'spika',
  'spikk',
  'spile',
  'spill',
  'spinn',
  'spion',
  'spire',
  'spise',
  'spisk',
  'spiss',
  'spjær',
  'splid',
  'spole',
  'spord',
  'spore',
  'sport',
  'spott',
  'spove',
  'sprak',
  'spray',
  'sprek',
  'sprik',
  'sprit',
  'sprut',
  'spryd',
  'språk',
  'spuns',
  'spurt',
  'spurv',
  'spyle',
  'spytt',
  'spøke',
  'spøte',
  'spøtt',
  'squaw',
  'stage',
  'stake',
  'stakk',
  'stall',
  'stamp',
  'stand',
  'stane',
  'stang',
  'stank',
  'stans',
  'stapp',
  'stare',
  'starr',
  'start',
  'stase',
  'staup',
  'staur',
  'staut',
  'stave',
  'stavn',
  'stede',
  'stege',
  'stegg',
  'steik',
  'steil',
  'stein',
  'steke',
  'stele',
  'stell',
  'steng',
  'stenk',
  'sterk',
  'stett',
  'stevn',
  'stift',
  'stige',
  'stikk',
  'stile',
  'stilk',
  'still',
  'stime',
  'sting',
  'stinn',
  'stive',
  'stode',
  'stoff',
  'stokk',
  'stola',
  'stole',
  'stoll',
  'stolt',
  'stomp',
  'stopp',
  'stork',
  'storm',
  'story',
  'stove',
  'strak',
  'stram',
  'strek',
  'strev',
  'strid',
  'strie',
  'stril',
  'strut',
  'stryk',
  'strøk',
  'strøm',
  'stubb',
  'stuer',
  'stuke',
  'stukk',
  'stump',
  'stund',
  'stunt',
  'stupa',
  'stupe',
  'sture',
  'stuss',
  'stutt',
  'stygg',
  'stykk',
  'style',
  'styre',
  'styrt',
  'ståke',
  'støkk',
  'støle',
  'stønn',
  'støpe',
  'støte',
  'støtt',
  'støve',
  'støye',
  'støyp',
  'støyt',
  'subbe',
  'subus',
  'sugen',
  'suger',
  'sugge',
  'suite',
  'sukat',
  'sukke',
  'sukle',
  'sukre',
  'sulke',
  'sulky',
  'sulle',
  'sulte',
  'summe',
  'sunna',
  'super',
  'suppe',
  'surfe',
  'surle',
  'surne',
  'surre',
  'suser',
  'suset',
  'sushi',
  'susle',
  'susse',
  'sutre',
  'sutte',
  'sutur',
  'svada',
  'svage',
  'svaie',
  'svale',
  'svamp',
  'svane',
  'svang',
  'svans',
  'svare',
  'svart',
  'svarv',
  'svear',
  'sveig',
  'svein',
  'sveip',
  'sveis',
  'sveit',
  'sveiv',
  'svekk',
  'svele',
  'svelg',
  'svell',
  'svenn',
  'svepe',
  'sverd',
  'sverm',
  'svett',
  'sveve',
  'svike',
  'svikt',
  'svill',
  'svime',
  'svine',
  'sving',
  'svinn',
  'svint',
  'svipp',
  'svire',
  'svive',
  'svolk',
  'svull',
  'svupp',
  'svæve',
  'svøpe',
  'swazi',
  'swing',
  'sydpå',
  'sykle',
  'sykne',
  'sylfe',
  'sylta',
  'sylte',
  'symre',
  'synde',
  'synes',
  'synge',
  'synke',
  'synse',
  'synsk',
  'synål',
  'syrer',
  'syrin',
  'syrne',
  'sysle',
  'sytet',
  'sytti',
  'sytøy',
  'syver',
  'sådan',
  'såfrø',
  'sågar',
  'sålde',
  'såtid',
  'sælde',
  'særbo',
  'særbu',
  'sødme',
  'søken',
  'søker',
  'søkke',
  'sølet',
  'sølje',
  'sømme',
  'sønna',
  'søple',
  'søren',
  'sørge',
  'sørpe',
  'sørpå',
  'sørve',
  'søten',
  'søyle',
  'tabbe',
  'tablå',
  'tafse',
  'tagal',
  'tagge',
  'tagne',
  'taiga',
  'taime',
  'takke',
  'takle',
  'takse',
  'takst',
  'takås',
  'taler',
  'talje',
  'talle',
  'tally',
  'tamil',
  'tampe',
  'tamse',
  'tanga',
  'tange',
  'tango',
  'tanke',
  'tanks',
  'tanne',
  'tante',
  'tapas',
  'taper',
  'tapet',
  'tapir',
  'tappe',
  'tasal',
  'taske',
  'tasle',
  'tasse',
  'taste',
  'tatar',
  'tavle',
  'teddy',
  'tefat',
  'tefte',
  'tegne',
  'teine',
  'teint',
  'teipe',
  'teist',
  'teite',
  'tekke',
  'tekno',
  'tekst',
  'telet',
  'telle',
  'telne',
  'telte',
  'temme',
  'tempo',
  'tenar',
  'tenke',
  'tenne',
  'tenor',
  'tenår',
  'teori',
  'teppe',
  'terge',
  'terme',
  'terne',
  'terpe',
  'terra',
  'terte',
  'tesil',
  'tesla',
  'teste',
  'tetne',
  'tette',
  'tevle',
  'texas',
  'thorn',
  'tiara',
  'tidel',
  'tidig',
  'tiger',
  'tigge',
  'tikke',
  'tilbe',
  'tilby',
  'tilde',
  'tilgi',
  'tilje',
  'tilrå',
  'tilsi',
  'tilså',
  'tilta',
  'tilte',
  'times',
  'timid',
  'tinde',
  'tinge',
  'tinte',
  'tiple',
  'tippe',
  'tipse',
  'tirre',
  'tiske',
  'tisle',
  'tispe',
  'tisse',
  'titan',
  'titid',
  'titte',
  'tiårs',
  'tiøre',
  'tjafs',
  'tjalk',
  'tjatt',
  'tjeld',
  'tjene',
  'tjern',
  'tjone',
  'tjore',
  'tjukk',
  'tjuve',
  'tjåke',
  'tjære',
  'toast',
  'tobbe',
  'tobis',
  'toddi',
  'toddy',
  'toene',
  'tofte',
  'tolar',
  'tolke',
  'tolle',
  'tomat',
  'tomme',
  'tomse',
  'tomte',
  'tonal',
  'tonem',
  'topas',
  'toppe',
  'torsk',
  'torso',
  'torve',
  'tosse',
  'total',
  'totem',
  'totid',
  'touch',
  'toårs',
  'toøre',
  'trace',
  'trade',
  'trafo',
  'trake',
  'trakk',
  'trakt',
  'traké',
  'trale',
  'trall',
  'tralt',
  'tramp',
  'trane',
  'trang',
  'trans',
  'trant',
  'trapp',
  'trase',
  'trass',
  'trasé',
  'trave',
  'treak',
  'treen',
  'treer',
  'treet',
  'treff',
  'trege',
  'treig',
  'trekk',
  'trell',
  'trema',
  'trend',
  'trene',
  'tresk',
  'tress',
  'trett',
  'trevl',
  'trial',
  'trias',
  'trikk',
  'triks',
  'trill',
  'trine',
  'trinn',
  'triol',
  'tripp',
  'trips',
  'trise',
  'trist',
  'tritt',
  'trive',
  'trofe',
  'trofé',
  'troke',
  'troké',
  'troll',
  'trone',
  'trope',
  'tropp',
  'tross',
  'trost',
  'truck',
  'truen',
  'truge',
  'trumf',
  'trunk',
  'trupp',
  'truse',
  'trust',
  'trutt',
  'trygd',
  'trygg',
  'trykk',
  'trykt',
  'tryne',
  'tryte',
  'tråde',
  'tråkk',
  'tråle',
  'træle',
  'trøsk',
  'trøst',
  'trøtt',
  'trøye',
  'tuete',
  'tufse',
  'tufte',
  'tugge',
  'tukan',
  'tukle',
  'tukte',
  'tulle',
  'tumle',
  'tumor',
  'tuner',
  'tunge',
  'tuppe',
  'turbo',
  'turne',
  'turné',
  'turve',
  'tusen',
  'tusje',
  'tuske',
  'tusle',
  'tusse',
  'tuste',
  'tutel',
  'tutle',
  'tutor',
  'tutsi',
  'tutti',
  'tvang',
  'tvare',
  'tvebo',
  'tvebu',
  'tverr',
  'tvers',
  'tvert',
  'tvett',
  'tvile',
  'tvill',
  'tvinn',
  'tvist',
  'tvære',
  'tweed',
  'twist',
  'tyder',
  'tyfon',
  'tyfus',
  'tyføs',
  'tygge',
  'tykke',
  'tykne',
  'tylft',
  'tylle',
  'tynge',
  'tynne',
  'typus',
  'tysse',
  'tyste',
  'tyved',
  'tyvjo',
  'tåket',
  'tålig',
  'tåpen',
  'tåret',
  'tårne',
  'tøffe',
  'tøfle',
  'tøler',
  'tømme',
  'tømre',
  'tønne',
  'tørke',
  'tørne',
  'tørre',
  'tørst',
  'tøset',
  'tøtsj',
  'tøvet',
  'tøvær',
  'tøyle',
  'tøyse',
  'tøyte',
  'uanet',
  'ubedt',
  'ubest',
  'ublid',
  'ubona',
  'ubont',
  'udekt',
  'udelt',
  'udryg',
  'udrøy',
  'udøpt',
  'uedel',
  'uegna',
  'uekte',
  'uenig',
  'ufiks',
  'ufisk',
  'ufora',
  'ufred',
  'ufset',
  'ufyse',
  'ufødt',
  'uføre',
  'ugagn',
  'uggen',
  'ugget',
  'ugift',
  'ugild',
  'uglad',
  'ugras',
  'ugrei',
  'ugrer',
  'uheil',
  'uhell',
  'uhuga',
  'uhyre',
  'uhørt',
  'uigur',
  'ujamn',
  'ujevn',
  'uklar',
  'uklok',
  'ulada',
  'uladd',
  'ulage',
  'ulest',
  'ulike',
  'ullen',
  'ullus',
  'ulyst',
  'ulåst',
  'ulærd',
  'ulært',
  'ulønt',
  'uløst',
  'umake',
  'umalt',
  'umbra',
  'umiak',
  'unavn',
  'under',
  'undre',
  'unett',
  'union',
  'unnbe',
  'unngå',
  'unnse',
  'unnsi',
  'unnta',
  'unote',
  'unåde',
  'upara',
  'upopp',
  'upris',
  'urban',
  'uredd',
  'urede',
  'urein',
  'uremi',
  'urete',
  'urett',
  'urtid',
  'urund',
  'urven',
  'urøkt',
  'urørt',
  'usagt',
  'usams',
  'usann',
  'usett',
  'uskap',
  'usmak',
  'usona',
  'usont',
  'ussel',
  'usunn',
  'usyra',
  'usyrt',
  'usådd',
  'usåra',
  'utakk',
  'utakt',
  'utall',
  'utapå',
  'utarv',
  'utasa',
  'utast',
  'utbre',
  'utbud',
  'utdør',
  'utedo',
  'utemt',
  'utett',
  'utfor',
  'utfri',
  'uthus',
  'uting',
  'utkik',
  'utkok',
  'utleg',
  'utlyd',
  'utlån',
  'utløe',
  'utløp',
  'utmed',
  'utmål',
  'utopi',
  'utrop',
  'utror',
  'utsig',
  'utstå',
  'utsyn',
  'utsæd',
  'uttak',
  'uttur',
  'utukt',
  'utved',
  'utveg',
  'utvei',
  'utvær',
  'utøse',
  'utøve',
  'uvane',
  'uvant',
  'uvenn',
  'uvett',
  'uviss',
  'uåpna',
  'uøvet',
  'vabbe',
  'vable',
  'vader',
  'vadre',
  'vafle',
  'vagge',
  'vagle',
  'vaier',
  'vaken',
  'vaker',
  'vakle',
  'vakne',
  'vakte',
  'valen',
  'valid',
  'valke',
  'valle',
  'valma',
  'valne',
  'valpe',
  'valse',
  'valte',
  'valør',
  'vande',
  'vange',
  'vanke',
  'vanne',
  'vanry',
  'vante',
  'varde',
  'varia',
  'varig',
  'varme',
  'varpe',
  'varte',
  'vaset',
  'vaske',
  'vasle',
  'vasse',
  'vater',
  'vatne',
  'vatre',
  'vavre',
  'vebbe',
  'vedbu',
  'vedde',
  'vedgå',
  'vedta',
  'vefte',
  'vegan',
  'vegne',
  'vegre',
  'veide',
  'veidn',
  'veike',
  'veile',
  'veite',
  'veive',
  'vekke',
  'vekne',
  'vekst',
  'vekte',
  'velar',
  'velde',
  'velge',
  'velin',
  'velle',
  'velsk',
  'velte',
  'velum',
  'velur',
  'vemod',
  'vende',
  'venne',
  'vente',
  'venøs',
  'verdi',
  'verdt',
  'verft',
  'verge',
  'verke',
  'verne',
  'verpe',
  'verre',
  'verst',
  'verve',
  'vesal',
  'vesel',
  'vesen',
  'vesir',
  'veske',
  'vesla',
  'vesle',
  'vesta',
  'vette',
  'vever',
  'vidde',
  'viden',
  'video',
  'vidje',
  'vifte',
  'vigge',
  'vigil',
  'vigle',
  'vigør',
  'vikar',
  'vikke',
  'vikle',
  'viksk',
  'vikte',
  'vilje',
  'villa',
  'ville',
  'vimen',
  'vimet',
  'vimre',
  'vimse',
  'vinde',
  'vindu',
  'vinga',
  'vinge',
  'vinke',
  'vinne',
  'vinsj',
  'vinst',
  'vinyl',
  'vinøs',
  'vippe',
  'vipse',
  'virak',
  'viral',
  'viril',
  'virke',
  'virre',
  'virus',
  'viser',
  'visir',
  'viske',
  'visle',
  'visne',
  'vispe',
  'visse',
  'visst',
  'visum',
  'vital',
  'viten',
  'vitje',
  'vitne',
  'vitse',
  'vivat',
  'vodka',
  'voffe',
  'vogge',
  'voile',
  'vokal',
  'vokke',
  'vokse',
  'vokte',
  'volde',
  'volof',
  'volte',
  'volum',
  'volve',
  'vonom',
  'vorde',
  'vorte',
  'votum',
  'vovet',
  'vrake',
  'vrang',
  'vrede',
  'vrien',
  'vrier',
  'vrikk',
  'vrist',
  'vræle',
  'vrøvl',
  'vugge',
  'vulke',
  'vulst',
  'vulva',
  'vågal',
  'våken',
  'våkne',
  'vånde',
  'våpen',
  'våres',
  'våryr',
  'våset',
  'våtne',
  'væpne',
  'væren',
  'væske',
  'væter',
  'vørde',
  'webbe',
  'weber',
  'whist',
  'wokke',
  'xenie',
  'xenon',
  'xhosa',
  'yacht',
  'yatzy',
  'ydmyk',
  'ynder',
  'yndig',
  'yngel',
  'ynges',
  'yngle',
  'yngst',
  'ynkes',
  'yppal',
  'yppig',
  'yrhet',
  'ystel',
  'yster',
  'zappe',
  'zloty',
  'zoome',
  'Østen',
  'ågrer',
  'ånder',
  'åpner',
  'årbok',
  'årbuk',
  'årete',
  'åring',
  'årlig',
  'årsak',
  'årvak',
  'årveg',
  'årvei',
  'åsete',
  'åsing',
  'åssen',
  'åstak',
  'åsted',
  'åtsel',
  'åtter',
  'åverk',
  'ægide',
  'ærbar',
  'ærdun',
  'ærend',
  'ærlig',
  'ødsel',
  'ødsle',
  'øking',
  'øksel',
  'øksle',
  'ølete',
  'ølfat',
  'ølost',
  'ømhet',
  'ønske',
  'ørken',
  'ørret',
  'ørske',
  'ørsmå',
  'ørten',
  'ørtug',
  'øskje',
  'østpå',
  'østre',
  'øving',
  'øvrig',
  'øyder',
  'øying',
]
