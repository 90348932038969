import { MigrationStats } from '@/components/modals/MigrateStatsModal'

import { decrypt, encrypt } from './encryption'
import { loadStats } from './stats'

const gameStateKey = 'gameState'
const archiveGameStateKey = 'archiveGameState'
const highContrastKey = 'highContrast'

export type StoredGameState = {
  guesses: string[]
  solution: string
}

export const saveGameStateToLocalStorage = (
  isLatestGame: boolean,
  gameState: StoredGameState
) => {
  const key = isLatestGame ? gameStateKey : archiveGameStateKey
  localStorage.setItem(key, JSON.stringify(gameState))

  const searchParams = new URLSearchParams(document.location.search)
}

export const loadGameStateFromLocalStorage = (isLatestGame: boolean) => {
  const key = isLatestGame ? gameStateKey : archiveGameStateKey
  const state = localStorage.getItem(key)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const transmitStats = (
  stats?: GameStats,
  gameState?: StoredGameState
) => {
  if (!stats) {
    stats = loadStats()
  }
  if (!gameState) {
    gameState = loadGameStateFromLocalStorage(true)!
  }

  const migrationStats: MigrationStats = {
    statistics: stats,
    gameState: gameState,
  }
  console.log(migrationStats)
  const emigrationCode = encrypt(JSON.stringify(migrationStats))
  const shareObj = { type: 'stats', message: emigrationCode }
  console.log('SENT STATS:')
  console.log(emigrationCode)
  window.parent.postMessage(JSON.stringify(shareObj), '*')
}

export const recceiveStats = () => {
  const searchParams = new URLSearchParams(document.location.search)
  if (searchParams.get('stats')) {
    const stats: string = searchParams.get('stats') ?? ''
    //console.log(stats)
    try {
      var migrationStats = JSON.parse(decrypt(stats) ?? '')
      console.log('PARSED RECEIVED STATS:')
      console.log(migrationStats)
      if (migrationStats.gameState) {
        saveGameStateToLocalStorage(true, migrationStats.gameState)
      }

      if (migrationStats.statistics) {
        saveStatsToLocalStorage(migrationStats.statistics)
      }
    } catch (err) {
      console.log('Unable to parse encrypted stats')
      console.log(err)
    }
  }
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
  transmitSettings()
}

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey)
  return highContrast === '1'
}

export const setStoredNotificationsEnabled = (
  notificationsEnabled: boolean
) => {
  if (notificationsEnabled) {
    localStorage.setItem('notificationsEnabled', '1')
    if (!localStorage.getItem('notificationHour')) {
      localStorage.setItem('notificationHour', '0')
    }
  } else {
    localStorage.removeItem('notificationsEnabled')
  }
  transmitSettings()
}

export const getStoredNotificationsEnabled = () => {
  const notifications = localStorage.getItem('notificationsEnabled')
  return notifications === '1'
}

export const getStoredNotificationHour = () => {
  const hour = localStorage.getItem('notificationHour')
  if (hour) {
    return hour
  } else {
    return '0'
  }
}

export const setStoredNotificationHour = (notificationHour: string) => {
  if (getStoredNotificationsEnabled() && notificationHour) {
    localStorage.setItem('notificationHour', notificationHour)
  } else {
    localStorage.removeItem('notificationHour')
  }
  transmitSettings()
}

const settingsKeys = [
  highContrastKey,
  'notificationsEnabled',
  'notificationHour',
  'gameMode',
]

export type SettingObject = {
  key: string
  value: string
}

export type SettingObjectTransmittable = {
  type: string
  message: SettingObject[]
}

export const transmitSettings = () => {
  const shareObj: SettingObjectTransmittable = { type: 'settings', message: [] }
  settingsKeys.map((setting) => {
    const value = localStorage.getItem(setting)
    if (value) {
      shareObj.message.push({
        key: setting,
        value: value,
      })
    }
  })
  console.log('WEB: SENT SETTINGS')
  console.log(shareObj)
  window.parent.postMessage(JSON.stringify(shareObj), '*')
}

export const recceiveSettings = () => {
  const searchParams = new URLSearchParams(document.location.search)
  settingsKeys.map((setting) => {
    if (searchParams.get(setting)) {
      const value: string = searchParams.get(setting) ?? ''
      try {
        localStorage.setItem(setting, value)
      } catch (err) {
        console.log('Unable to set setting')
        console.log(err)
      }
    }
  })
}
