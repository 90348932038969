export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Flott!', 'Fantastisk!', 'Supert!']
export const GAME_COPIED_MESSAGE = 'Spillet ble kopiert til utklippstavlen'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'For få bokstaver'
export const WORD_NOT_FOUND_MESSAGE = 'Ord ikke funnet'
export const HARD_MODE_ALERT_MESSAGE =
  'Vanskelig modus kan bare skrus på før start'
export const HARD_MODE_DESCRIPTION =
  'Alle avslørte hint må brukes i følgende gjetteforsøk'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Øker kontrasten'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Ordet var ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Du må bruke ${guess} på plass ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Gjett må inneholde ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Slett'
export const STATISTICS_TITLE = 'Statistikk'
export const GUESS_DISTRIBUTION_TEXT = 'Fordeling av spill'
export const NEW_WORD_TEXT = 'Nytt ord kommer om'
export const SHARE_TEXT = 'Del resultat'
export const SHARE_FAILURE_TEXT =
  'Klarte ikke å dele resultatet. Denne funksjonen er bare tilgjengelig i sikre kontekster (HTTPS)'
export const MIGRATE_BUTTON_TEXT = 'Overfør'
export const MIGRATE_DESCRIPTION_TEXT =
  'Klikk her for å overføre din statistikk til en ny enhet.'
export const TOTAL_TRIES_TEXT = 'Antall forsøk'
export const SUCCESS_RATE_TEXT = 'Suksessrate'
export const CURRENT_STREAK_TEXT = 'Nåværende streak'
export const BEST_STREAK_TEXT = 'Beste streak'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  'Du bruker en innebygd nettleser og kan oppleve problemer med å dele og lagre dine resultater. Vi oppfordrer deg til å bruke den vanlige nettleseren til enheten din.'
export const DATEPICKER_TITLE = 'Velg en tidligere dato'
export const DATEPICKER_CHOOSE_TEXT = 'Velg'
export const DATEPICKER_TODAY_TEXT = 'i dag'
export const ARCHIVE_GAMEDATE_TEXT = 'Spilldato'
export const ENABLE_NOTIFICATIONS_DESCRIPTION =
  'Varsler deg når et nytt ord er ute'
