import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Fragment, useEffect, useState } from 'react'

type Props = {
  selectedHour?: string
  onClick: Function
  notificationsEnabled: boolean
}

export type NotificationHourOption = {
  hour: string
  hourString: string
  description: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const NotificationDropdown = ({
  selectedHour,
  onClick,
  notificationsEnabled,
}: Props) => {
  function click(option: NotificationHourOption) {
    selectedHour = option.hour
    onClick(selectedHour)
    return
  }
  const options: NotificationHourOption[] = [
    { hour: '0', hourString: 'kl. 00', description: 'øyeblikkelig' },
    { hour: '8', hourString: 'kl. 08', description: 'morgen' },
    { hour: '12', hourString: 'kl. 12', description: 'formiddag' },
    { hour: '17', hourString: 'kl. 17', description: 'ettermiddag' },
    { hour: '20', hourString: 'kl. 20', description: 'kveld' },
  ]
  const [selectedOption, setSelectedOption] = useState(options[0])

  useEffect(() => {
    options.map((option: NotificationHourOption) => {
      if (option.hour === selectedHour) {
        console.log(option)
        setSelectedOption(option)
      }
    })
  }, [selectedHour, notificationsEnabled])

  return (
    <div className="flex items-center justify-between gap-4 py-3">
      <div className="items-center text-left text-gray-500 dark:text-gray-300">
        <p className="leading-none">Tidspunkt for varsling</p>
        <p className="text-xs text-gray-500 dark:text-gray-300">
          Velger når vi sender varselet
        </p>
      </div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md  bg-indigo-600 px-4 py-2 text-center text-base font-medium text-white shadow-sm hover:bg-indigo-700  sm:text-sm">
            {selectedOption.hourString}
            <ChevronDownIcon
              className="ml-2 h-6 w-6 cursor-pointer dark:stroke-white"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option, index) => (
                <Menu.Item key={index}>
                  <a
                    onClick={() => click(option)}
                    className={classNames(
                      selectedOption.hour === option.hour
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    <b>{option.hourString}</b> {option.description}
                  </a>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
