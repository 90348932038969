import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'

type Props = {
  title: string
  children: React.ReactNode
  hideTitleOnApp: boolean
  centered: boolean
  closeButtonTitle: string
  isOpen: boolean
  handleClose: () => void
}

// different styling based on if running as app
let overlayClass =
  'fixed inset-0 min-h-screen bg-gray-500 bg-opacity-75 transition-opacity'
let modalClass =
  'inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all dark:bg-gray-800 sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle'
let modalParent =
  'flex min-h-full items-center justify-center py-10 px-4 text-center sm:p-0'
let buttonClass = 'hidden'
let closeIconClass = 'absolute right-4 top-4'
let titleClass =
  'text-xl text-center font-medium leading-6 text-gray-900 dark:text-gray-100'
let marginBelowTitle = 'text-center mt-2'

const searchParams = new URLSearchParams(document.location.search)
if (searchParams.get('app') === 'true') {
  overlayClass = 'fixed inset-0 min-h-screen'
  modalClass =
    'transform overflow-hidden flex flex-col bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all dark:bg-gray-800 w-min-full min-h-screen align-middle items-center'
  modalParent = 'min-h-full items-center justify-center text-center sm:p-0'
  buttonClass =
    'mt-auto w-full inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-left text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-gray-200 disabled:bg-white disabled:text-gray-900 disabled:focus:outline-none disabled:dark:border-gray-600 disabled:dark:bg-gray-800 disabled:dark:text-gray-400 sm:text-sm'
  closeIconClass = 'hidden'
  titleClass =
    'text-3xl text-left font-bold leading-6 text-gray-900 dark:text-gray-100 '
  marginBelowTitle = 'text-center mt-5'
}

export const BaseModal = ({
  title,
  children,
  hideTitleOnApp,
  centered,
  closeButtonTitle,
  isOpen,
  handleClose,
}: Props) => {
  let centeredClass = ''
  let titleClassApp = ''
  if (hideTitleOnApp && searchParams.get('app')) {
    titleClassApp = ' hidden'
  }

  if (centered) {
    centeredClass = 'centered'
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="dark:darkBg fixed inset-0 z-10 overflow-y-auto"
        onClose={handleClose}
      >
        <div className={modalParent}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={overlayClass} />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={modalClass}>
              <button
                onClick={() => handleClose()}
                tabIndex={0}
                aria-pressed="false"
                className={closeIconClass}
              >
                <XCircleIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
              </button>
              <div className={centeredClass}>
                <div>
                  <Dialog.Title as="h3" className={titleClass + titleClassApp}>
                    {title}
                  </Dialog.Title>
                  <div className={marginBelowTitle}>{children}</div>
                </div>
              </div>
              <button
                onClick={() => handleClose()}
                type="button"
                className={buttonClass}
              >
                {closeButtonTitle}
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
