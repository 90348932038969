import { useEffect, useState } from 'react'

import {
  ENABLE_NOTIFICATIONS_DESCRIPTION,
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import { MigrationIntro } from '../stats/MigrationIntro'
import { BaseModal } from './BaseModal'
import { NotificationDropdown } from './NotificationDropdown'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  notificationsEnabled: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleNotificationTime: Function
  notificationHour: string
  handleMigrateStatsButton: () => void
  handleNotifications: Function
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  notificationsEnabled,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleNotificationTime,
  notificationHour,
  handleMigrateStatsButton,
  handleNotifications,
  handleHighContrastMode,
}: Props) => {
  let appMode = false
  const searchParams = new URLSearchParams(document.location.search)
  if (searchParams.get('app') === 'true') {
    appMode = true
  }

  return (
    <BaseModal
      title="Innstillinger"
      hideTitleOnApp={false}
      centered={false}
      isOpen={isOpen}
      closeButtonTitle="Ferdig"
      handleClose={handleClose}
    >
      <div className="mt-2 flex flex-col divide-y">
        <SettingsToggle
          settingName="Vanskelig modus"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Mørkt tema"
          hidden={appMode}
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="Høykontrastmodus"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Påminnelser"
          hidden={!appMode}
          flag={notificationsEnabled}
          handleFlag={handleNotifications}
          description={ENABLE_NOTIFICATIONS_DESCRIPTION}
        />
        {notificationsEnabled && appMode && (
          <NotificationDropdown
            notificationsEnabled={notificationsEnabled}
            selectedHour={notificationHour}
            onClick={handleNotificationTime}
          ></NotificationDropdown>
        )}
        <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
      </div>
    </BaseModal>
  )
}
